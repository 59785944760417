import { useEffect, useState } from 'react';
import datetimeDifference from "datetime-difference";
import { DDay } from '../constants';
import '../styles/countdown.scss';

var pad = require('pad-left');

const getTime = (countDown) => {
  
  if(countDown.days > 0) {
    return [countDown.days, countDown.hours + 1, 'days', 'hours'];
  }
  else if(countDown.hours > 0) {
    return [countDown.hours, countDown.minutes + 1, 'hours', 'minutes'];
  }
  else if(countDown.minutes > 0) {
    return [countDown.minutes, countDown.seconds, 'minutes', 'seconds'];
  }
  else {
    return [0, 0, 'minutes', 'seconds'];
  }
}

export const CountDown = () => {
  
  const [countDown, setCountDown] = useState(datetimeDifference(DDay, new Date(Date.now())));
  const [timeType, setTimeType] = useState('today');
  const [firstTime, secondTime, fistTimeType, secondTimeType] = getTime(countDown);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(datetimeDifference(DDay, new Date(Date.now())));
      const currentDate = new Date(Date.now());
      if(currentDate.getDate() === DDay.getDate() && currentDate.getMonth() === DDay.getMonth() && currentDate.getFullYear() === DDay.getFullYear()) {
        setTimeType('today');
      }
      else if(Date.now() < DDay.getTime()) {
        setTimeType('future');
      }
      else  {
        setTimeType('past');
      }
    }, 1000)
    return () => clearInterval(interval)
  })
  
  return (
    <div className='countdown-timer'>
      <div className="time-prefix">{timeType === 'future' ? 'in': timeType === 'today' ? 'is': 'was'}</div>
      {timeType !== 'today' && (
        <>
          <div className='countdown-timer__time'>{pad(firstTime, 2, 0)}
            <div className="time-label">{fistTimeType}</div>
          </div>
          <div className='countdown-timer__time'>{pad(secondTime, 2, 0)}
            <div className="time-label">{secondTimeType}</div>
          </div>
        </>
      )}
      {timeType === 'today' && (
        <div className='d-day countdown-timer__time'>Today</div>
      )}
      {timeType === 'past' && <div className="time-suffix">ago</div>}
    </div>
  );
}