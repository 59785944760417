import { useCallback, useState } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import Lightbox from "yet-another-react-lightbox";
import LazyLoad from "react-lazyload";

import { getData } from "../db/timeline";

import "yet-another-react-lightbox/styles.css";
import '../styles/timeline.scss';


const timelinesDB = await getData();
// const miscTimeLines = timelinesDB.filter((timeline) => String(timeline.date).includes('misc'));
const datedTimeLines = timelinesDB.filter((timeline) => !timeline.misc || timeline.misc !== 1);
const timelines = (datedTimeLines ?? []).sort((a, b) => Number(b.date) - Number(a.date));


const miscTimelines = timelinesDB.filter((timeline) => timeline.misc && timeline.misc === 1);
console.log(timelines, miscTimelines);

const base_url = 'https://firebasestorage.googleapis.com/v0/b/ayushi-bday.appspot.com/o/timeline%2F{media_item}?alt=media'

const getImgSrc = (media_name) => {
  return base_url.replace('{media_item}', media_name);
}

export const Timeline = () => {

  const [currentTimeline, setCurrentTimeline] = useState(null);

  const togglePreviews = useCallback((index, show = true) => {
    if(show) {
      setCurrentTimeline(timelines[index]);
    }
    else {
      setCurrentTimeline(null);
    }
  }, [setCurrentTimeline]);

  const toggleMiscellaneousPreviews = useCallback((index, show = true) => {
    if(show) {
      setCurrentTimeline(miscTimelines[index]);
    }
    else {
      setCurrentTimeline(null);
    }
  }, [setCurrentTimeline]);

  const isMiscTimeline = (timeline) => {
    return timeline.misc && timeline.misc === 1;
  };

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  
  const renderImages = useCallback((images) => {
    if(Array.isArray(images) && images.length > 1) {
      return images.slice(0, 2).map((image, index) => {
        
        return (
          
            <img className='timeline-image' src={getImgSrc(image)} alt={`timeline-${index}`} key={index} />
        )
      })
    }
    else if(Array.isArray(images) && images.length === 1) {
      return (
          <img className='timeline-image' src={getImgSrc(images[0])} alt='timeline' />
      )
    }
  }, []);

  const renderPhotoSlider = useCallback((timeline) => {
    if(!timeline) return null;
    
    const images = timeline.images.map((image, i) => ({ src: getImgSrc(image), key: `${timeline.date}-image-${i}` }));
    
    return (
      <Lightbox
        open={true}
        close={() => togglePreviews(0, false)}
        slides={images}
      />
    )
  }, [togglePreviews]);

  const renderTimeline = useCallback((timelines) => {
    return timelines.map((timeline, index) => {
      console.log('renderTimeline', timeline, index);

      const toggle = isMiscTimeline(timeline) ? toggleMiscellaneousPreviews: togglePreviews;
      
      return (
        <div className='timeline__event' key={`${timeline.date}-event-${index}`}>
          {(index % 2 === 0 || isSmallDevice) && (
            <div className='timeline__event__date left'>{timeline.date}<div className="circle"></div></div>
          )}
          <div className='timeline__event__description'>
            <div className='timeline-image__wrapper' onClick={() => toggle(index, true)}><LazyLoad once="true">{renderImages(timeline.images)}</LazyLoad></div>
          </div>
          {index % 2 !== 0 && !isSmallDevice && (
            <div className='timeline__event__date right'>{timeline.date}<div className="circle"></div></div>
          )}
        </div>
      )
    })
  }, [isSmallDevice, renderImages, togglePreviews, toggleMiscellaneousPreviews]);
  return (
    <div>
      <div className='timeline'>
        {renderTimeline(timelines)}
        {renderTimeline(miscTimelines)}
      </div>
      {renderPhotoSlider(currentTimeline)}
    </div>
  );
}